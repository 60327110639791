import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button, Input } from 'reactstrap';
import { exportPDF, exportExcel } from '../../helpers/ExportFile';
import DeleteButton from './DeleteButton';
import { useParams } from 'react-router-dom';
import TooltipComponent from '../views/ToolTip';
import ActionModal from './ActionModal';
import Swal from 'sweetalert2';
import { generateFactura } from '../../helpers/Factura';

const GenericTable = ({ entity, typeEntity, columns, fetchData, fetchSearchData, onEdit, onDelete, exportTitle, reloadTrigger, showSearch, showPagination, customActions, onSumaTotalChange }) => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [activeTooltip, setActiveTooltip] = useState(null);
    const itemsPerPage = 10;
    const { id } = useParams();
    // Modal-related states
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [actionType, setActionType] = useState('');
    const [monto, setMonto] = useState('');


    const handleAction = (rowData, type) => {
        setSelectedRow(rowData);
        setActionType(type);
        setModalOpen(true);
    };

    const handleConfirmAction = async () => {
        if (!monto || isNaN(monto)) {
            Swal.fire('Error', 'Por favor ingrese un monto válido.', 'error');
            return;
        }

        const payload = {
            monto: parseFloat(monto),
            id: selectedRow.id, // Aquí se incluye el ID del registro
        };

        try {
            const response = await fetch(`/api/${entity}/${actionType.toLowerCase()}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                Swal.fire('Éxito', `${actionType} realizado correctamente.`, 'success');
                setModalOpen(false);
                setMonto('');
                setData((prevData) =>
                    prevData.map((row) =>
                        row.id === selectedRow.id ? { ...row, saldo: row.saldo - payload.monto } : row
                    )
                );
            } else {
                Swal.fire('Error', 'Ocurrió un error al realizar la operación.', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Ocurrió un error al realizar la operación.', 'error');
        }
    };

    const handleGenerateFactura = (rowData) => {
        generateFactura(rowData);
    };


    useEffect(() => {
        const loadData = async () => {
            
            const result = searchTerm
                ? await fetchSearchData(searchTerm)
                : await fetchData(currentPage, itemsPerPage, id);
            
            if (result && result.data) {
                
                if (onSumaTotalChange) {
                    let suma = 0;
                    result.data.forEach((item) => {
                        if (item.total !== undefined) {
                            suma += parseInt(item.total, 10);
                        }
                    });
                    onSumaTotalChange(suma);
                }

                setData(result.data);
                setCurrentPage(result.currentPage);
                setTotalPages(result.totalPages);
            } else {
                console.error('Error: result is undefined');
            }
        };
        loadData();
    }, [currentPage, searchTerm, reloadTrigger]);

    const handleSearch = (event) => { 
        setSearchTerm(event.target.value);
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const formatNumber = (value) => {
        if (typeof value === 'number') {
            // Asegura que se aplique formato de millares
            return value.toLocaleString('es-ES', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            });
        }
        return value; // Si no es un número, devuelve el valor tal cual
    };

    const handleUpdateRow = (updatedRow) => {
        if (!updatedRow) {
            console.error('updatedRow es undefined o nulo. No se puede actualizar la fila.');
            return;
        }

        setData((prevData) =>
            prevData.map((row) => (row.id === updatedRow.id ? updatedRow : row))
        );
    };


    return (
        <div>
            {!showSearch && (
                <div className="d-flex justify-content-between align-items-center mt-5 mb-3 search-input-container">
                    <div>
                        <Button className="me-2 buttons-export" onClick={() => exportExcel(entity, columns, exportTitle)}>
                            Excel
                        </Button>
                        <Button className="buttons-export" onClick={() => exportPDF(entity, columns, exportTitle)}>
                            PDF
                        </Button>
                    </div>
                    <Input
                        type="text"
                        placeholder="Buscar..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="search-input"
                    />
                </div>
            )}
            <DataTable
                value={data}
                rows={itemsPerPage}
                className='data-table nowrap-cell'
            >
                {(entity === 'deudor' || entity === 'acreedor') && (
                    <Column
                        bodyClassName={'nowrap-cell'}
                        header="Acción"
                        body={(rowData) => (
                            <div className="text-center">
                                {rowData.deudor && (
                                    <Button color="success" onClick={() => handleAction(rowData, 'Cobrar')}>
                                        Cobrar
                                    </Button>
                                )}
                                {rowData.proveedor && (
                                    <Button color="danger" onClick={() => handleAction(rowData, 'Pagar')}>
                                        Pagar
                                    </Button>
                                )}
                            </div>
                        )}
                    />
                )}
                {columns.map((column, index) => (
                    <Column
                        key={index}
                        field={column.accessor}
                        header={column.header}
                        body={(rowData) => {
                            const value = rowData[column.accessor];
                            const tooltipId = `tooltip-${index}-${rowData.id}`;

                            // Si hay una función de renderización personalizada, úsala
                            if (column.render) {
                                return column.render(rowData);
                            }

                            // Si es numérico, aplica formateo
                            if (column.isNumeric) {
                                return formatNumber(value);
                            }

                            // Si es un texto largo, agrega tooltip
                            if (typeof value === 'string' && value.length > 50) {
                                return (
                                    <>
                                        <span
                                            onMouseEnter={(e) => setActiveTooltip({ id: tooltipId, target: e.target, content: value })}
                                            onMouseLeave={() => setActiveTooltip(null)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {value.substring(0, 50)}...
                                        </span>
                                        {activeTooltip && activeTooltip.id === tooltipId && (
                                            <TooltipComponent content={activeTooltip.content} target={activeTooltip.target} />
                                        )}
                                    </>
                                );
                            }

                            // Para cualquier otro caso, devuelve el valor tal cual
                            return value;
                        }}
                        bodyClassName="nowrap-cell"
                    />
                ))}
                <Column bodyClassName={'nowrap-cell'}
                    header="Acciones"
                    body={(rowData) => (
                        <div className="text-center">
                            {customActions && customActions(rowData)}
                            <Button color="warning" className="me-2" onClick={() => onEdit(rowData)}>
                                <i className="bi bi-pencil-fill"></i> Editar
                            </Button>
                            <div className="d-inline-block me-2">
                                <DeleteButton
                                    entity={entity}
                                    typeEntity={typeEntity}
                                    entityId={rowData.id}
                                    onDeleteSuccess={(entityId) => {
                                        const newData = data.filter(item => item.id !== entityId);
                                        setData(newData);
                                        if (onDelete) {
                                            onDelete(entityId);
                                        }
                                    }}
                                />
                            </div>
                            {entity === 'venta' && ( // Mostrar solo si la entidad es "venta"
                                <Button
                                    color="info"
                                    className="me-2"
                                    style={{ marginRight: '0.5rem' }}
                                    onClick={() => handleGenerateFactura(rowData)}
                                >
                                    <i className="bi bi-file-earmark-text"></i> Factura
                                </Button>
                            )}
                        </div>
                    )}
                />

            </DataTable>
            {!showPagination && (
                <div className="pagination mt-3">
                    <span className="me-3 mt-2">{`Página ${currentPage} de ${totalPages}`}</span>
                    <Button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        Anterior
                    </Button>
                    <Button
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="ms-2"
                    >
                        Siguiente
                    </Button>
                </div>
            )}
            <ActionModal
                isOpen={modalOpen}
                toggle={() => setModalOpen(!modalOpen)}
                actionType={actionType}
                apiEndpoint={`${entity}/${actionType.toLowerCase()}`}
                selectedRow={selectedRow} // El registro completo, incluido el ID
                monto={monto}
                setMonto={setMonto}
                handleConfirmAction={handleConfirmAction}
                onSuccess={handleUpdateRow}
            />

        </div>
    );
};

export default GenericTable;
