import SysparLogo from "../../../assets/images/logos/SYSPAR_GRAL.png";

const Logo = () => {
  return (
    <div className="logoHeader">
      <img src={SysparLogo} alt="Syspar Logo" style={{ width: "200px" }} />
    </div>
  );
};

export default Logo;

